import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
//
// NOTE: these are highly recommended, since they
//       1) help customers to find relevant locations, and
//       2) reduce the cost of using map providers geocoding API
const defaultLocations = [
  {
    id: 'default-warsaw',
    predictionPlace: {
      address: 'Warsaw, Poland',
      bounds: new LatLngBounds(new LatLng(52.3676, 21.0714), new LatLng(52.2265, 20.8223)),
    },
  },
  {
    id: 'default-krakow',
    predictionPlace: {
      address: 'Krakow, Poland',
      bounds: new LatLngBounds(new LatLng(50.0935, 19.9926), new LatLng(49.9632, 19.7847)),
    },
  },
  {
    id: 'default-lodz',
    predictionPlace: {
      address: 'Lodz, Poland',
      bounds: new LatLngBounds(new LatLng(51.8439, 19.5939), new LatLng(51.6498, 19.3497)),
    },
  },
  {
    id: 'default-wroclaw',
    predictionPlace: {
      address: 'Wroclaw, Poland',
      bounds: new LatLngBounds(new LatLng(51.1919, 17.1375), new LatLng(50.9985, 16.8308)),
    },
  },
  {
    id: 'default-poznan',
    predictionPlace: {
      address: 'Poznan, Poland',
      bounds: new LatLngBounds(new LatLng(52.4765, 17.1052), new LatLng(52.3387, 16.7331)),
    },
  },
];
export default defaultLocations;
