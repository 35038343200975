/**
 * This is a wrapper component for different Layouts.
 * Navigational 'aside' content should be added to this wrapper.
 */
import React, { useEffect } from 'react';
import { node, number, string, shape } from 'prop-types';
import { compose } from 'redux';

import { FormattedMessage } from '../../../util/reactIntl';
import { withViewport } from '../../../util/uiHelpers';

import { TabNav } from '../..';

import { createGlobalState } from './hookGlobalState';

import css from './LayoutSideNavigation.module.css';
import { scrollToTab } from './LayoutWrapperAccountSettingsSideNav';

const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

// Add global state for tab scrolling effect
const initialScrollState = { scrollLeft: 0 };
const { useGlobalState } = createGlobalState(initialScrollState);

// Horizontal scroll animation using element.scrollTo()

const LayoutWrapperVendorProfileSettingsSideNavComponent = props => {
  const [scrollLeft, setScrollLeft] = useGlobalState('scrollLeft');

  useEffect(() => {
    const { currentPage, viewport } = props;
    let scrollTimeout = null;

    const { width } = viewport;
    const hasViewport = width > 0;
    const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;

    // Check if scrollToTab call is needed (tab is not visible on mobile)
    if (hasHorizontalTabLayout) {
      scrollTimeout = window.setTimeout(() => {
        scrollToTab(currentPage, scrollLeft, setScrollLeft);
      }, 300);
    }

    return () => {
      // Update scroll position when unmounting
      const el = document.querySelector(`#${currentPage}Tab`);
      setScrollLeft(el.parentElement.scrollLeft);
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
    };
  });

  const { currentPage } = props;

  const tabs = [
    {
      text: <FormattedMessage id="VendorProfileSettingsSideNav.ProfileSettingsPageTab" />,
      selected: currentPage === 'ProfileSettingsPage',
      id: 'ProfileSettingsPageTab',
      linkProps: { name: 'ProfileSettingsPage' }
    },
    {
      text: <FormattedMessage id="VendorProfileSettingsSideNav.VendorDetailsPageTab" />,
      selected: currentPage === 'VendorDetailsPage',
      id: 'VendorDetailsPageTab',
      linkProps: { name: 'VendorDetailsPage' }
    },
    {
      text: <FormattedMessage id="VendorProfileSettingsSideNav.VendorOpeningHoursPageTab" />,
      selected: currentPage === 'VendorOpeningHoursPage',
      id: 'VendorOpeningHoursPageTab',
      linkProps: { name: 'VendorOpeningHoursPage' }
    }
  ];

  return <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabs} />;
};

LayoutWrapperVendorProfileSettingsSideNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  currentPage: null,
};

LayoutWrapperVendorProfileSettingsSideNavComponent.propTypes = {
  children: node,
  className: string,
  rootClassName: string,
  currentPage: string,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const LayoutWrapperVendorProfileSettingsSideNav = compose(withViewport)(
  LayoutWrapperVendorProfileSettingsSideNavComponent
);

export default LayoutWrapperVendorProfileSettingsSideNav;
