import merge from 'lodash/merge';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { fetchCurrentUser, currentUserShowSuccess } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SAVE_VENDOR_DETAILS_REQUEST = 'app/VendorDetailsPage/SAVE_VENDOR_DETAILS_REQUEST';
export const SAVE_VENDOR_DETAILS_SUCCESS = 'app/VendorDetailsPage/SAVE_VENDOR_DETAILS_SUCCESS';
export const SAVE_VENDOR_INFO_ERROR = 'app/VendorDetailsPage/SAVE_VENDOR_INFO_ERROR'
export const SAVE_VENDOR_SOCIALS_ERROR = 'app/VendorDetailsPage/SAVE_VENDOR_SOCIALS_ERROR'

export const SAVE_VENDOR_DETAILS_CLEAR = 'app/VendorDetailsPage/SAVE_VENDOR_DETAILS_CLEAR';

// ================ Reducer ================ //

const initialState = {
  saveVendorInfoError: null,
  saveVendorSocialsError: null,
  saveVendorDetailsInProgress: false,
  vendorDetailsChanged: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_VENDOR_DETAILS_REQUEST:
      return {
        ...state,
        saveVendorDetailsInProgress: true,
        saveVendorInfoError: null,
        saveVendorSocialsError: null,
        vendorDetailsChanged: false,
      };
    case SAVE_VENDOR_DETAILS_SUCCESS:
      return { ...state, saveVendorDetailsInProgress: false, vendorDetailsChanged: true };
    case SAVE_VENDOR_INFO_ERROR:
      return { ...state, saveVendorDetailsInProgress: false, saveVendorInfoError: payload };
    case SAVE_VENDOR_SOCIALS_ERROR:
      return { ...state, saveVendorDetailsInProgress: false, saveVendorSocialsError: payload };

    case SAVE_VENDOR_DETAILS_CLEAR:
      return {
        ...state,
        saveVendorDetailsInProgress: false,
        saveVendorInfoError: null,
        saveVendorSocialsError: null,
        vendorDetailsChanged: false,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveVendorDetailsRequest = () => ({ type: SAVE_VENDOR_DETAILS_REQUEST });
export const saveVendorDetailsSuccess = () => ({ type: SAVE_VENDOR_DETAILS_SUCCESS });

export const saveError = (type) => error => ({
  type,
  payload: error,
  error: true,
});

export const saveVendorInfoError = saveError(SAVE_VENDOR_INFO_ERROR)
export const saveVendorSocialsError = saveError(SAVE_VENDOR_SOCIALS_ERROR)

export const saveVendorDetailsClear = () => ({ type: SAVE_VENDOR_DETAILS_CLEAR });

// ================ Thunks ================ //

const updateProfile = (publicData, dispatch, sdk, dispatchFn) => sdk.currentUser
  .updateProfile(
    { publicData },
    {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    }
  )
  .then(response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
    }

    const currentUser = entities[0];
    return currentUser;
  })
  .catch(e => {
    dispatch(dispatchFn(storableError(e)));
    // pass the same error so that the SAVE_VENDOR_DETAILS_SUCCESS
    // action will not be fired
    throw e;
  });

const updateUser = (dispatch, dispatchFn) => dispatch(dispatchFn).then(user => {
  dispatch(currentUserShowSuccess(user));
  dispatch(saveVendorDetailsSuccess());
})
// error action dispatched in requestSaveEmail
.catch(e => null)

const requestSaveVendorInfo = params => (dispatch, getState, sdk) => {
  const info = { ...params.info, address: params.info?.address ? params.info.address  : ''}
  return updateProfile({ info }, dispatch, sdk, saveVendorInfoError);
}
const saveVendorInfo = params => (dispatch) => updateUser(dispatch, requestSaveVendorInfo(params))

const requestSaveVendorSocials = params => (dispatch, getState, sdk) => {
  return updateProfile({ socials: params.socials }, dispatch, sdk, saveVendorSocialsError);
}
const saveVendorSocials = params => (dispatch) => updateUser(dispatch, requestSaveVendorSocials(params))

const saveAllDetails = params => (dispatch, getState, sdk) => {
  const { info, socials } = params;
  const promises = [
    dispatch(requestSaveVendorInfo({ info })),
    dispatch(requestSaveVendorSocials({ socials })),
  ];

  return Promise.all(promises)
    .then(values => {
      const saveVendorInfo = values[0];
      const saveVendorSocials = values[1];

      const currentUser = merge(saveVendorSocials, saveVendorInfo);
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(saveVendorDetailsSuccess());
    })
    .catch(e => null);
};

export const saveVendorDetails = params => (dispatch, getState, sdk) => {
  dispatch(saveVendorDetailsRequest());

  const { info = {}, currentInfo, socials = {}, currentSocials } = params;

  const hasChanged = (oldObj, newObj) => {
    if (Object.entries(oldObj).length !== Object.entries(newObj).length) return true;

    return Object.entries(oldObj).some(([key, value]) => value !== newObj[key]);
  }

  const infoHasChanged = hasChanged(currentInfo, info);
  const socialsHasChanged = hasChanged(currentSocials, socials);

  if (infoHasChanged && socialsHasChanged) {
    return dispatch(saveAllDetails({ info, socials }));
  } else if (infoHasChanged) {
    return dispatch(saveVendorInfo({ info }));
  } else if (socialsHasChanged) {
    return dispatch(saveVendorSocials({ socials }));
  } else {
    return dispatch(saveVendorDetailsSuccess());
  }
};

export const loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};
