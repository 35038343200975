import moment from 'moment';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { fetchCurrentUser } from '../../ducks/user.duck';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/ListingSchedulePage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ListingSchedulePage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ListingSchedulePage/FETCH_LISTINGS_ERROR';

export const ADD_OWN_ENTITIES = 'app/ListingSchedulePage/ADD_OWN_ENTITIES';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  listings: [],
};

const listingSchedulePageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case ADD_OWN_ENTITIES:
      return {
        ...state,
        listings: payload,
      };

    default:
      return state;
  }
};

export default listingSchedulePageReducer;

// ================ Selectors ================ //

export const getOwnListings = (state) => {
  return state.ListingSchedulePage.listings;
};

// ================ Action creators ================ //

export const addOwnEntities = listings => ({
  type: ADD_OWN_ENTITIES,
  payload: listings,
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const getBookingData = (props, dispatch, sdk) => {
  return sdk.bookings.query({
    ...props,
    include: ['transaction'],
  })
    .then(response => {
      const relatedTransaction = id => response.data.included.find((item) => item.id.uuid === id);
      return response.data.data.map(({ relationships, ...rest }) => ({
        ...rest,
        transactionId: relationships.transaction.data.id.uuid,
        transaction: relatedTransaction(relationships.transaction.data.id.uuid),
      }))
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    })
}

const getTimeSlots = (props, dispatch, sdk) => {
  return sdk.timeslots.query(props)
    .then(response => response.data.data.map(({ id: { uuid: id }, attributes: { start, end } }) => ({ id, start, end })))
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    })
}

const getAvailabilityExceptions = (props, dispatch, sdk) => {
  return sdk.availabilityExceptions.query(props)
    .then(response => response.data.data.map(({ id: { uuid: id }, attributes: { start, end } }) => ({ id, start, end })))
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    })
}

// Throwing error for new (loadData may need that info)
export const queryOwnListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, perPage };

  const start = moment().subtract(1, 'day').toDate();
  const end = moment(start).add(60, 'days').toDate();
  const bookingStart = moment(start).subtract(30, 'day').toDate();

  return sdk.listings
    .query(params)
    .then(response => {
      const data = response.data.data.map(async (listing) => {
        const { id, attributes, relationships } = listing
        const bookings = await getBookingData({ listingId: id, start: bookingStart, end }, dispatch, sdk);
        const timeSlots = await getTimeSlots({ listingId: id, start, end }, dispatch, sdk);
        const availabilityExceptions = await getAvailabilityExceptions({ listingId: id, start, end }, dispatch, sdk);
        const imageId = relationships.images.data[0].id.uuid;
        const relatedImage = response.data.included.find(({ id: { uuid } }) => uuid === imageId);

        return {
          id,
          title: attributes.title,
          listing,
          bookings,
          timeSlots,
          availabilityExceptions,
          image: relatedImage.attributes.variants['listing-card'].url,
        }
      })

      Promise.all(data).then(data => {
        dispatch(addOwnEntities(data));
        dispatch(queryListingsSuccess(response));
      })

      return response;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  const authorId = getState().user.currentUser?.id?.uuid;
  console.log('authorId:', authorId)

  const params = {
    ...queryParams,
    page,
    authorId,
    perPage: RESULT_PAGE_SIZE,
    include: ['images', 'currentStock'],
    'fields.image': [`variants.${variantPrefix}`],
    ...createImageVariantConfig(`${variantPrefix}`, 48, aspectRatio),
    'limit.images': 1,
  }

  return dispatch(queryOwnListings(params));
};
