import merge from 'lodash/merge';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { fetchCurrentUser, currentUserShowSuccess } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SAVE_VENDOR_OPENING_HOURS_REQUEST = 'app/VendorOpeningHoursPage/SAVE_VENDOR_OPENING_HOURS_REQUEST';
export const SAVE_VENDOR_OPENING_HOURS_SUCCESS = 'app/VendorOpeningHoursPage/SAVE_VENDOR_OPENING_HOURS_SUCCESS';
export const SAVE_VENDOR_OPENING_HOURS_ERROR = 'app/VendorOpeningHoursPage/SAVE_OPENING_HOURS_ERROR';
export const SAVE_VENDOR_OPENING_HOURS_CLEAR = 'app/VendorOpeningHoursPage/SAVE_VENDOR_OPENING_HOURS_CLEAR';

// ================ Reducer ================ //

const initialState = {
  saveOpeningHoursError: null,
  saveVendorOpeningHoursInProgress: false,
  vendorOpeningHoursChanged: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_VENDOR_OPENING_HOURS_REQUEST:
      return {
        ...state,
        saveVendorOpeningHoursInProgress: true,
        saveOpeningHoursError: null,
        vendorOpeningHoursChanged: false,
      };
    case SAVE_VENDOR_OPENING_HOURS_SUCCESS:
      return { ...state, saveVendorOpeningHoursInProgress: false, vendorOpeningHoursChanged: true };
    case SAVE_VENDOR_OPENING_HOURS_ERROR:
      return { ...state, saveVendorOpeningHoursInProgress: false, saveOpeningHoursError: payload };

    case SAVE_VENDOR_OPENING_HOURS_CLEAR:
      return {
        ...state,
        saveVendorOpeningHoursInProgress: false,
        saveOpeningHoursError: null,
        vendorOpeningHoursChanged: false,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveVendorOpeningHoursRequest = () => ({ type: SAVE_VENDOR_OPENING_HOURS_REQUEST });
export const saveVendorOpeningHoursSuccess = () => ({ type: SAVE_VENDOR_OPENING_HOURS_SUCCESS });

export const saveError = (type) => error => ({
  type,
  payload: error,
  error: true,
});

export const saveOpeningHoursError = saveError(SAVE_VENDOR_OPENING_HOURS_ERROR)

export const saveVendorOpeningHoursClear = () => ({ type: SAVE_VENDOR_OPENING_HOURS_CLEAR });

// ================ Thunks ================ //

const requestSaveOpeningHours = params => (dispatch, getState, sdk) => {
  const openingHours = params.openingHours;

  return sdk.currentUser
    .updateProfile(
      { publicData: { openingHours } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveOpeningHoursError(storableError(e)));
      throw e;
    });
};

const saveOpeningHours = params => (dispatch, getState, sdk) => {
  return (
    dispatch(requestSaveOpeningHours(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(saveVendorOpeningHoursSuccess());
      })
      .catch(e => null)
  );
};

export const saveVendorOpeningHours = params => (dispatch, getState, sdk) => {
  dispatch(saveVendorOpeningHoursRequest());

  const { openingHours, currentOpeningHours } = params;
  const openingHoursChanged = openingHours !== currentOpeningHours;

  if (openingHoursChanged) {
    return dispatch(saveOpeningHours({ openingHours }));
  }
};

export const loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};
